import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ImagesModule from "../images"

export default ({imageAlt}) => {

    const { Picto } = ImagesModule()

    const { t } = useTranslation('cities')
    const tCities = t('cities:cities')

    return (

        <div className="section">
            <div className="container">

                <div className="columns is-centered is-vcentered mb-6">
                    <div className="column is-3 is-offset-1 is-hidden-mobile">
                        <Picto name="partout-en-belgique"
                            alt={imageAlt}
                            style={{ filter: "brightness(0) saturate(100%) invert(7%) sepia(12%) saturate(6149%) hue-rotate(186deg) brightness(94%) contrast(91%)" }}
                        />
                    </div>
                    <div className="column is-4">
                        <article className="message is-large is-light">
                            <div className="message-header">
                                <h3 className="title is-3 has-text-primary is-uppercase has-text-centered">{t('cities:everywhere_in_belgium')}</h3>
                            </div>
                            <div className="message-body">
                                <ul style={{ columnCount: 2 }}>
                                    {Object.values(tCities).map(city => <li key={city}><span className="has-text-weight-bold has-text-primary">#&nbsp;</span>{city}</li>)}
                                    <li>{t('cities:cities_and_more')}</li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}