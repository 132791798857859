import React from "react"
import ImagesModule from "../../components/images"
import {useTranslation} from "gatsby-plugin-react-i18next"

const APropos = ({title, content, picture, imageAlt}) => {

    const { Picture } = ImagesModule()

    const {t} = useTranslation('sections')

    return (
        <section className="section">
            <div className="container">
                <h2 className="title is-3 is-uppercase has-text-centered mb-6">
                    {title ? title : t('about').default_title}
                </h2>
                <div className="columns is-vcentered">
                    <div className="column is-3 has-background-dark has-text-light px-5 py-5 mx-3">
                        {content}
                    </div>
                    <div className="column is-negative-offset-1 px-0 py-0">
                        <Picture name={picture} style={{zIndex: -1}} alt={imageAlt}></Picture>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default APropos