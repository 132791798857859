import React from "react"
import CTA from "../elements/cta-button"

export default ({ title, subtitle, cta, products, toggle }) => {

    return (
        <div className="section">
            <div className="container">
                <div className="columns is-multiline is-centered">
                    <div className="column is-4">
                        <article className="message is-large is-light">
                            <div className="message-body">
                                <h2 className="title is-3 has-text-primary is-uppercase has-text-centered">
                                    {title}
                                </h2>
                                {subtitle}
                                {cta && <p className="has-text-centered">
                                    <CTA onClick={toggle}>{cta}</CTA>
                                </p>}
                            </div>
                        </article>

                    </div>
                    {products.map((card, index) => (
                        <div className="column is-4" key={card.title + index}>
                            <article className="message is-large is-light">
                                <div className="message-header">
                                    <h3 className="title is-uppercase has-text-dark">{card.title}</h3>
                                </div>
                                <div className="message-body">
                                    {card.content}
                                </div>
                            </article>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}