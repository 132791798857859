import React from "react"
import ImagesModule from "../images"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default (props) => {

    const { Picture, Logo } = ImagesModule()
    const { title, category } = props

    const {t} = useTranslation('testimonials')
    const tTestimonials = t('testimonials')

    let testimonials
    if (category) testimonials = tTestimonials.filter(testimonial => testimonial._category === category)
    else testimonials = tTestimonials.filter(
        testimonial =>
            testimonial.businessname === "Microsoft"
            || testimonial.businessname === "IMS Health"
            || testimonial.businessname === "COFELY"
    )

    return (
        <section className="section">
            <div className="container has-text-centered">
                <h2 className="title is-3 is-uppercase">
                    {title}
                </h2>

                <div className="columns">
                    {testimonials.map(card => (
                        <div className="column is-one-third" key={card._logo}>
                            <div className="card card-equal-height">
                                <div className="card-image">
                                    <Picture name={card._picture} alt={card.image_alt} />
                                </div>
                                <div className="card-content">
                                    <h3 className="title is-size-4 has-text-dark">
                                        {card.title}
                                    </h3>
                                    <p className="is-size-6 mb-4">{card.description}</p>
                                </div>
                                <div className="card-footer has-no-border mr-5 mb-3" style={{ marginLeft: "auto" }}>
                                    <div className="is-size-5 has-text-right has-text-weight-bold">
                                        <Logo
                                            name={card._logo}
                                            style={{ maxWidth: "70px", marginLeft: "auto", marginBottom: "0.25em" }}
                                        />
                                        <p>
                                            {card.name && card.name + ","} <br />{card.businessname}
                                            {card.businesslocalization && " (" + card.businesslocalization + ")"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}