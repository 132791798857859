import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import APropos from "../components/sections/a-propos"
import TextWithPictos from "../components/sections/text-with-pictos"
import References from "../components/sections/references"
import Testimonials from "../components/sections/testimonials"
import TextImageHero from "../components/sections/text-image-hero"
import Cards from "../components/sections/cards"
import SectionCTA from "../components/sections/section-cta"
import useModal from "../components/use-modal"
import CTAButton from "../components/elements/cta-button"
import Cities from "../components/sections/cities"
import Faq, { FaqH, FaqP } from "../components/elements/faq"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

const SeminairesEtCongresPage = () => {

    const [toggle, ContactModal] = useModal()

    const { t } = useTranslation(['page_seminaries', 'slugs'])
    const tMeta = t('meta')
    const tHero = t('hero')
    const tAbout = t('about')
    const tAdvantages = t('advantages')
    const tReferences = t('references')
    const tProducts = t('products')
    const tTestimonials = t('testimonials')
    const tLastCTA = t('last_cta')
    const tFaq = t('faq')
    const tCities = t('cities')
    const tSlugs = t('slugs:slugs')

    return (
        <Layout>
            <ContactModal />
            <Seo
                title={tMeta.title}
                description={tMeta.description}
            />
            <TextImageHero
                picture="seminaires_et_congres"
                title={tHero.title}
                subtitle={tHero.subtitle}
                cta={tHero.cta}
                toggle={toggle}
                imageAlt={tHero.image_alt}
            />
            <APropos
                title={tAbout.title}
                imageAlt={tAbout.image_alt}
                content={
                    <>
                        <p className="mb-3">
                            {tAbout.p1}
                        </p>
                        <Link
                            to={tSlugs["/a-propos-de-semonbusiness/"]}
                            className="button is-large is-medium-mobile is-primary is-uppercase has-text-weight-bold is-rounded mb-1"
                        >
                            {tAbout.cta}
                        </Link>
                    </>
                }
                picture="semonbusiness-team-seminaries-organizers"
            ></APropos>
            <TextWithPictos
                className="mt-6"
                title={tAdvantages.title}
                pictos={["organisation-gratuite", "depuis-2004", "partout-en-belgique",]}
                pictosAlt={[tAdvantages.picto_1, tAdvantages.picto_2, tAdvantages.picto_3]}
            >
                <p className="mb-2">
                    {tAdvantages.p1}
                </p>
                <p className="mb-2">
                    {tAdvantages.p2}
                </p>
                <p className="mb-2">
                    {tAdvantages.p3}
                </p>
                <p className="mb-2">
                    {tAdvantages.p4}
                </p>
            </TextWithPictos>
            <References
                className="mt-6"
                title={tReferences.title}
            />
            <Cards
                title={tProducts.title}
                toggle={toggle}
                cta={tProducts.cta}
                products={tProducts.products.map(product => {
                    product.content =
                        <>
                            <p className="mb-3">
                                {product.text}
                            </p>
                            <p className="has-text-centered">
                                <CTAButton small className="is-outlined" onClick={toggle}>{tProducts.product_cta}</CTAButton>
                            </p>
                        </>
                    return product
                })}
            />
            <Cities imageAlt={tCities.image_alt} />
            <Testimonials
                category="seminaire"
                title={tTestimonials.title} />
            <SectionCTA
                title={tLastCTA.title}
                picture="together-palms"
                toggle={toggle}
                imageAlt={tLastCTA.image_alt}
            >
                <p className="mb-5">
                    {tLastCTA.p1}
                </p>
                <p>
                    {tLastCTA.p2}
                </p>
            </SectionCTA>
            <section className="section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-6">
                            <h2 className="title is-2">{tFaq.title}</h2>
                            <Faq
                                title={tFaq.faqs[0].title}
                                excerpt={tFaq.faqs[0].excerpt}
                            >
                                <FaqP>
                                    {tFaq.faqs[0].p1}
                                </FaqP>
                            </Faq>
                            <Faq
                                title={tFaq.faqs[1].title}
                                excerpt={tFaq.faqs[1].excerpt}
                            >
                                <FaqP>
                                    {tFaq.faqs[1].p1}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[1].p2}
                                </FaqP>
                            </Faq>
                            <Faq
                                title={tFaq.faqs[2].title}
                                excerpt={tFaq.faqs[2].excerpt}
                            >
                                <FaqP>
                                    {tFaq.faqs[2].p1}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p2}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p3}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[2].h1}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[2].p4}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p5}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p6}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[2].h2}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[2].p7}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[2].h3}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[2].p8}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p9}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[2].h4}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[2].p10}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p11}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[2].h5}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[2].p12}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p13}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p14}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p15}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[2].p16}
                                </FaqP>
                            </Faq>
                            <Faq
                                title={tFaq.faqs[3].title}
                                excerpt={tFaq.faqs[3].excerpt}
                            >
                                <FaqH>
                                    {tFaq.faqs[3].h1}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[3].p1}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[3].p2}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[3].h2}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[3].p3}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[3].h3}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[3].p4}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[3].h4}
                                </FaqH>
                                <FaqP>
                                    <div className="content">
                                        <ul>
                                            {
                                                tFaq.faqs[3].li1.map(li => <li key={li}>{li}</li>)
                                            }
                                        </ul>
                                    </div>
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[3].h5}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[3].p5}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[3].p6}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[3].p7}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[3].p8}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[3].h6}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[3].p9}
                                </FaqP>
                                <FaqP>
                                    {tFaq.faqs[3].p10}
                                </FaqP>
                                <FaqH>
                                    {tFaq.faqs[3].h7}
                                </FaqH>
                                <FaqP>
                                    {tFaq.faqs[3].p11}
                                </FaqP>
                            </Faq>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default SeminairesEtCongresPage


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;