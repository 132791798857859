import React from "react"
import ImagesModule from "../images"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default (props) => {

  const { Picto } = ImagesModule()
  const { title, children, className, pictos, pictosAlt, ...rest } = props
  const { t } = useTranslation('pictos')
  const tPictos = t('pictos')

  return (

    <section
      className={["section has-background-dark has-text-light", className].join(" ")}
      {...rest}
    >
      <div className="container has-text-centered is-medium">
        <div className="columns is-vcentered">
          <div className="column">
            <h2 className="title is-3 is-uppercase">
              {title}
            </h2>
            {children}
          </div>

          <div className="column">
            <div className="columns is-mobile is-centered is-vcentered is-multiline-touch">
              {
                pictos
                  .map((item, index) => (
                    <div className="column is-4-touch" key={item}>
                      <div className="card has-background-dark has-text-light">
                        <div className="card-image" style={{ minHeight: 130 }}>
                          <Picto name={item} alt={pictosAlt ? pictosAlt[index] : undefined} />
                        </div>
                        <div className="card-content" style={{ padding: "1.5rem 0.5rem" }}>
                          <p className="heading is-size-7-mobile">
                            <b>{tPictos[item]}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}