import React from "react"
import ImagesModule from "../images"
import CTA from "../elements/cta-button"

export default ({title, subtitle, cta, picture, toggle, imageAlt}) => {
    const { Picture } = ImagesModule()

    return (
    <section className="hero">
        <div className="hero-body">
            <div className="columns">
                <div className="column is-6">
                    <Picture name={picture} alt={imageAlt} />
                </div>
                <div className="column is-5-desktop is-6-tablet is-3-widescreen">
                    <h1 className="title is-2 is-spaced">{title}</h1>
                    <h2 className="subtitle is-3">{subtitle}</h2>
                    <CTA onClick={toggle}>{cta}</CTA>
                </div>
            </div>
        </div>
    </section>

    )
}