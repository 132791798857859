import React from "react"

const Faq = ({ title, excerpt, children }) => {

    const [isRevealed, setIsRevealed] = React.useState(false)

    return (
        <>
            <p className="heading mt-5">
                {title}
            </p>
            <p className="is-size-7 mb-2">
                {excerpt} {!isRevealed && "..."}
                {!isRevealed && <><br /><button className="button is-white" onClick={() => setIsRevealed(true)}>+</button></>}
            </p>
            {
                isRevealed
                &&
                children
            }
        </>
    )
}

const FaqP = ({ children }) => {

    return (
        <p className="is-size-7 mb-2">
            {children}
        </p>
    )

}
const FaqH = ({children}) => {

    return (
        <h3 className="title is-primary is-size-7 mb-2 has-text-weight-normal">{children}</h3>
    )
}

export default Faq
export { FaqP, FaqH }