import React from "react"
import ImagesModule from "../images"
import Img from "gatsby-image"
import CTA from "../elements/cta-button"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default ({ title, className }) => {

  const [revealAllLogos, setRevealAllLogos] = React.useState(false)

  const { Logo, allLogos } = ImagesModule()

  const featuredLogos = ["ing", "sodexo", "saint-luc", "iba", "axa", "gsk", "stib", "dieteren", "cisco"]

  const nonFeaturedLogos = allLogos.filter(
    logo => !featuredLogos.includes(logo.name)
  )

  const { t } = useTranslation(['sections', 'slugs'])
  const tRef = t('references')
  const tSlugs = t('slugs:slugs')

  return (
    <section className={["section", className].join(" ")}>
      <div className="container has-text-centered is-medium">
        <div className="columns is-centered">
          <div className="column is-10">
            <h2 className="title is-3 is-uppercase mb-5">
              {title}
            </h2>
            <ul className="columns is-mobile is-multiline is-align-items-center is-justify-content-center">
              {
                featuredLogos.map(
                  (img, index) => (
                    <li key={img + index} className="column is-1-tablet is-2-mobile">
                      <Logo name={img} />
                    </li>
                  )
                )
              }
            </ul>
          </div>
        </div>
        {
          !revealAllLogos &&
          <button className="button is-primary is-outlined has-text-weight-bold" onClick={() => setRevealAllLogos(true)}>
            <span>{tRef.more}</span>
            <span className="icon">↓</span>
          </button>
        }
        {
          revealAllLogos &&
          <ul className="columns is-multiline is-mobile is-align-items-center is-justify-content-center">
            {
              nonFeaturedLogos.map(
                (logo, index) => (
                  <li key={logo + index} className="column is-1-tablet is-2-mobile">
                    <Img fluid={logo.childImageSharp.fluid} />
                  </li>
                )
              )
            }
          </ul>
        }
        {
          revealAllLogos &&
          <CTA to={tSlugs["/references-clients/"]} className="is-outlined" small>{tRef.read_testimonials}</CTA>
        }
      </div>
    </section>
  )
}